<template>
  <!-- 发票申请-->
  <div class="bill-apply" v-loading="tableDataLoading">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="可申请订单" name="first">
        <!-- <el-button @click="Invoicing()">申请订单</el-button> -->
        <el-table ref="multipleTable" :data="tableData" border tooltip-effect="dark" style="width: 100%"
          :header-cell-style="getRowClass" :row-style="{ height: '3.125rem' }" :cell-style="getCellStyle"
          @selection-change="handleSelectionChange">
          <el-table-column align="center" prop="orderSn" label="订单号信息" width="290" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="totalAmount" label="价格(元)" width="120" align="center" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="created" label="下单时间" width="300" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column align="center" width="190" label="操作">
            <template slot-scope="scope">
              <el-button plain size="small" @click="Invoicing(scope.row)">开票</el-button>
            </template>
          </el-table-column>
        </el-table>


        <!-- <div class="apply" @click="clickApply">申请开票</div> -->

      </el-tab-pane>
      <el-tab-pane label="我的发票" name="second">
        <el-table :data="BillData" tooltip-effect="dark" border :header-cell-style="getRowClass"
          :row-style="{ height: '4rem' }" :cell-style="getCellStyle">
          <el-table-column prop="billHeader" label="发票抬头" align="center" width="180" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="billAmount" label="发票总额" width="175" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="created" label="申请时间" width="175" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="typeMsg" label="发票类型" width="175" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column width="200" align="center" label="操作">
            <template slot-scope="scope">
              <el-button plain size="small" @click="clickDetails(scope.row)">详情</el-button>

              <el-button plain size="small" @click="clickWithdraw(scope.row)">删除申请</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="发票信息" name="third">
        <el-button @click="addBillMsg" style="margin-bottom: 20px; float: right;">添加发票信息</el-button>
        <el-table border tooltip-effect="dark" :data="billMsgData" :header-cell-style="getRowClass"
          :row-style="{ height: '4rem' }" :cell-style="getCellStyle">
          <el-table-column align="center" prop="billHeader" label="抬头">
          </el-table-column>
          <el-table-column align="center" label="类型">
            <template slot-scope="scope">
                {{scope.row.type==0?"个人":"企业"}}
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="数量">
            <template slot-scope="scope">
                {{scope.row.billAmount?scope.row.billAmount:"暂无"}}
            </template>
          </el-table-column> -->
          <el-table-column align="center" label="税号">
            <template slot-scope="scope">
                {{scope.row.billIdentifyNum}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="企业地址">
            <template slot-scope="scope">
                {{scope.row.businessAddress}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="企业电话">
            <template slot-scope="scope">
                {{scope.row.businessPhone}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="银行账号">
            <template slot-scope="scope">
                {{scope.row.billBankAccount}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="开户银行">
            <template slot-scope="scope">
                {{scope.row.billBankName}}
            </template>
          </el-table-column>
          <!-- <el-table-column align="center" label="发票内容">
            <template slot-scope="scope">
                {{scope.row.billContent}}
            </template>
          </el-table-column> -->

          <el-table-column align="center" label="邮箱">
            <template slot-scope="scope">
                {{scope.row.billReceiverEmail}}
            </template>
          </el-table-column>
          <el-table-column align="center" label="手机号">
            <template slot-scope="scope">
                {{scope.row.billReceiverPhone}}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <div style="text-align:center;">
      <!--分页组件-->
      <el-pagination background @size-change="sizeChangeHandle" @current-change="currentChangeHandle"
        :current-page="page.pageIndex" :page-sizes="[10, 20, 50, 100]" :page-size="page.pageSize" :total="page.totalPage"
        layout="prev, pager, next" style="text-align: center;">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { httpGet } from "@/utils/httpRequest";
import { log } from "console";
export default {
  name: "BillApply",
  data() {
    return {
      // 加载开启
      tableDataLoading: true,
      activeName: 'first',
      tableData: [
        {
          certificateName: 12,
          totalAmount: 1100,
          created: "12:12:12"
        }
      ],
      BillData: [
      ],
      billMsgData: [

      ],
      // 被选中的列表
      multipleSelection: [],
      //分页对象
      page: {
        //当前页
        pageIndex: 1,
        //每页显示数据
        pageSize: 6,
        //总页数
        totalPage: 0,
      },
    }
  },
  methods: {
    // 添加发票信息
    addBillMsg(){
      this.$router.push(
        {
          name: 'BillHandle',
        }
      );
    },
    // 申请开票
    clickApply() {
      // console.log(this.multipleSelection)
      this.$router.push({ name: 'BillHandle', params: { data: this.multipleSelection } });
    },
    // 开票按钮
    Invoicing(data) {
      this.$router.push(
        {
          name: 'BillSelect'
        }
      );
    },
    // 详情页
    clickDetails(row) {
      // console.log(row)
      this.$router.push({ name: 'Bill', params: { billMsgId: row.billMsgId, type: row.type } });
    },
    // // 撤回申请
    clickWithdraw(row) {
      this.$confirm('你将删除此次申请, 是否继续?删除后不能再申请此发票', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log('----row', row);
        httpGet("/app/app-user-bill/delete",
          {
            billMsgId: row.billMsgId,
            userId: localStorage.getItem('userId')
          },
          (res) => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.getInvoiced()
          },
          (err) => {
            throw err
          }
        )

      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消撤回'
        });
      });

      // this.$router.push({ name: 'Bill', params: { id: 4 } });

    },
    // 每页数
    sizeChangeHandle(val) {
      this.page.pageSize = val;
      this.page.pageIndex = 1;
    },

    // 当前页
    currentChangeHandle(val) {
      this.page.pageIndex = val;
      if (this.activeName == "first") {
        this.getInvoice()
      } else if (this.activeName == "second") {
        this.getInvoiced()
      } else if (this.activeName == "third") {
        // console.log("third")
        this.getBillMsg()
      }
    },
    // tab切换
    handleClick() {
      if (this.activeName == "first") {
        this.getInvoice()
      } else if (this.activeName == "second") {
        this.getInvoiced()
      } else if (this.activeName == "third") {
        this.getBillMsg()
      }
    },
    // 请求可申请订单列表
    getInvoice() {
      this.tableDataLoading = true;
      httpGet("/app/app-order/apply/list",
        {
          size: this.page.pageSize,
          index: this.page.pageIndex,
        },
        (res) => {
          // console.log(res.data)
          this.page.totalPage = res.data.total
          this.tableData = res.data.records
          // console.log(res.data.records)
          // 关闭订单
          this.tableDataLoading = false
        },
        (err) => {
          if (err.status == false) {
            localStorage.clear();
            if (!localStorage.getItem("userId")) {
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
          // console.log(err.status)
          throw err
        }
      )
    },
    // 请求我的发票的数据列表
    getInvoiced() {
      this.tableDataLoading = true;
      httpGet("/app/app-user-bill/list",
        {
          userId: localStorage.getItem('userId'),
          size: this.page.pageSize,
          current: this.page.pageIndex,
        },
        (res) => {
          this.page.totalPage = res.data.total
          // res.data.records.forEach(index => {
          //   console.log(index.type)
          //   if (index.type == 0) {
          //     index.typeMsg = "个人"
          //     console.log(index.typeMsg)
          //   } else if (index.type = 1) {
          //     index.typeMsg = "企业"
          //     console.log(index.typeMsg)
          //   }
          // }) 
          // console.log(res.data)
          this.BillData = res.data.records
          this.tableDataLoading = false;
        },
        (err) => {
          if (err.status == false) {
            localStorage.clear();
            if (!localStorage.getItem("userId")) {
              this.$message.error('登录过期，请重新登录!')
              this.$router.push("/login")
            }
          }
           
          throw err
        }
      )
    },
    // 请求发票信息列表
    getBillMsg(){
      this.tableDataLoading = true;
      httpGet("/app/app-user-bill-msg/list",{
          // size: this.page.pageSize,
          // index: this.page.pageIndex,
      },(res)=>{
        this.billMsgData = res.data
        // console.log(this.billMsgData)
        this.tableDataLoading = false;
        this.page.totalPage = res.data.total
      },(err)=>{
         
      })
    },
    // 表格数据多选
    handleSelectionChange(val) {
      // console.log(val)
      this.multipleSelection = val;
    },
    //表头颜色
    getRowClass() {
      return "background:#EBECF0;color:#3E454D;height: '2.125rem'";
    },
    //表体样式
    getCellStyle() {
      return "font-size: 0.875rem;\n" +
        "font-family: PingFang SC-Medium, PingFang SC;\n" +
        "font-weight: 500;\n" +
        "color: #3E454D;";
    },
  },
  created() {
    this.getInvoice();
    // this.getInvoiced();
    // this.getBillMsg();
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
.bill-apply {
  padding: 0 32px;
  background: #FFFFFF;
   border-radius: 10px;
  height: 49.5rem;

  .apply {
    width: fit-content;
    padding: 0 1.8161%;
    height: 2rem;
    background: #1371E7;
    box-shadow: 0 0.25rem 0.375rem 0 rgba(19, 113, 231, 0.08);
    border-radius: 10px;
    font-size: 0.8125rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 2rem;
    text-align: center;
    margin-top: 1.25rem;
  }

  // 复选康
  ::v-deep .el-checkbox__inner {
    width: 1rem;
    height: 1rem;
  }

  ::v-deep .el-checkbox__inner::after {
    left: 0.3125rem;
    top: 0.125rem;
  }

  ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner,
  ::v-deep .el-checkbox__input.is-indeterminate .el-checkbox__inner {
    background-color: #1371E7;
    border-color: #1371E7;
  }

  // 表格
  ::v-deep .el-table__body {
    border: 0.0625rem solid #EBEEF5;
    border-collapse: collapse;
    width: auto !important;
    margin-top: 1.25rem;
  }

  ::v-deep .el-table--enable-row-transition .el-table__body td.el-table__cell {
    border: 0.0625rem solid #EBEEF5;
  }

  // tabs
  ::v-deep .el-tabs {
    background-color: #FFFFFF;
  }

  ::v-deep .el-tabs__active-bar {
    background-color: #1371E7;
    height: 0.25rem;
  }

  ::v-deep .el-tabs__item.is-active {
    font-weight: bolder;
    color: #000000;
  }

  ::v-deep .el-tabs__item {
    height: 4rem;
    line-height: 4rem;
    font-size: 0.9375rem;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    padding: 0 2.5rem;
    color: #667280;
  }

  ::-webkit-scrollbar {
    display: none;
    /* Chrome Safari */
  }

  ::v-deep .el-tabs__content {
    height: 40.0625rem;
    margin-top: 1.25rem;
    overflow: hidden;
    overflow-y: scroll;
    text-align: left;
  }
}
</style>